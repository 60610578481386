<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <h3>Affichage du client : {{ client.ref }}</h3>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
          >
            <button @click="$router.go(-1)" class="btn btn-primary" style="margin-bottom: 20px;">Retour</button>
          </b-col>
        </b-row>

        <b-tabs>
          <b-tab active>
            <template #title>
              <feather-icon icon="HomeIcon" />
              <span>Coordonnées</span>
            </template>
            <div class="m-2">
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                  class="align-items-left justify-content-start mb-2 mb-md-0"
                >
                  <b-card-text><strong>Référence :</strong> {{ client.ref }} </b-card-text>
                  <b-card-text><strong>Nom :</strong> {{ client.name }} </b-card-text>
                  <b-card-text><strong>Adresse :</strong> {{ client.adresse }} {{ client.cp }} {{ client.ville }}</b-card-text>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                  class="align-items-left justify-content-start mb-2 mb-md-0"
                >
                  <b-card-text><strong>Email :</strong> {{ client.email }} </b-card-text>
                  <b-card-text><strong>Téléphone :</strong> {{ client.phone }} </b-card-text>
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                  class="align-items-right justify-content-end mb-2 mb-md-0"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="success"
                    class="btn-icon rounded-circle float-right"
                    :href="'https://www.google.com/maps/search/?api=1&query='+client.adresse+' '+client.cp+' '+client.ville"
                    target="_blank"
                  >
                    <feather-icon icon="MapPinIcon" />
                  </b-button>
                </b-col>
              </b-row>
            </div>

          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon icon="UserIcon" />
              <span>Contact</span>
            </template>

            <!-- Table Top -->
            <b-row>

              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Afficher</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="pageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entrées</label>
              </b-col>

              <!-- Search -->
              <b-col
                cols="12"
                md="6"
              >
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="filter"
                    class="d-inline-block mr-1"
                    placeholder="Recherche..."
                  />
                </div>
              </b-col>
            </b-row>
            <!-- Tableau -->
            <b-row>
              <b-col>
                <b-table
                  id="datatables"
                  class="mt-4 mb-4"
                  :items="Contacts"
                  :fields="fields"
                  stacked="md"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  striped
                  small
                  @filtered="onFiltered"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              />
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>

      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BTabs,
  BTab,
  BLink,
  BDropdown,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import PouchDB from 'pouchdb'

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BTabs,
    BTab,
    BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: [
        { key: 'name', label: 'Nom', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'phone', label: 'Téléphone', sortable: true },
      ],
      client: [],
      Contacts: [],
      perPage: 5,
      currentPage: 1,
      totalRows: 1,
      currentPage: 1,
      pageOptions: [5, 10, 25, 50, 100],
      filter: null,
      outlined: true,
      filterOn: ['name', 'email'],
      sortBy: 'name',
      sortDirection: 'asc',
      sortDesc: false,
    }
  },
  computed: {
    /**
      * Total no. of records
      */
    rows() {
      return this.items.length
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  created() {
    this.localDB = new PouchDB('clients')
    this.remoteDB = new PouchDB('https://couchdb.placedusite.fr/clients')
    // this.localDB.sync(this.remoteDB, { live: true, retry: true })

    // Création d'un index de recherhe
    this.localDB.createIndex({
      index: { fields: ['ref'] },
    })

    // Lecture des documents sur l'index de recherche
    this.localDB.find({ selector: { ref: {$eq: this.$route.params.id }}, limit: 1 }, this.setClients)

    // this.localDB.get(this.$route.params.id, this.setClients)
  },
  customButtons: {
    default: true,
  },
  methods: {
    setClients(err, doc) {
      if (err) {
        console.log('err', err)
      }

      console.log('doc', doc.docs[0])
      
      // if (doc.hasOwnProperty('_ids')) {
        this.client = doc.docs[0]
      // }  

      if (doc.docs[0].hasOwnProperty('contacts')) {
        for (let i = 0; i < doc.docs[0].contacts.length; i++) {
          this.Contacts.push({
            name: doc.docs[0].contacts[i].name,
            email: doc.docs[0].contacts[i].email,
            phone: doc.docs[0].contacts[i].phone,
          })
        }
        this.totalRows = this.Contacts.length
      }  

    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style>
    .btn-primary {
        margin-right: 12px;
    }
    .per-page-selector {
      width: 90px;
    }
    .btNopadding button {
      padding: 0;
    }
    @media (max-width: 767.98px) {
      .table.b-table.b-table-stacked-md > tbody > tr > [data-label]::before {
        text-align: left;
      }
    }
</style>

<style lang="scss">
  @import '~@core/scss/vue/libs/vue-select.scss';
</style>
